/* You can add global styles to this file, and also import other style files */
@import "../node_modules/angular-archwizard/styles/archwizard.css";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "@fontsource/poppins";
@import "~angular-notifier/styles";

html {
  --scrollbarBG: #cfd8dc;
  --thumbBG: #90a4ae;
}

body::-webkit-scrollbar {
  width: 15px;
}

body {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}

body::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}

body::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}

html,
body {
  height: 100%;
  width: 100%;
}

body {
  font-family: "Poppins";
  background-color: #fdfdfd;
  font-size: 14px;
}

.cdk-global-overlay-wrapper,
.cdk-overlay-container {
  z-index: 9999 !important;
}


h2,
h3 {
  margin-bottom: 0;
}

.flex {
  display: flex;
}

.items-center {
  align-items: center;
}

.mr-2 {
  margin-right: 10px;
}

.mb-2 {
  margin-bottom: 10px;
}

.pulse {
  animation: pulse 1.5s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  animation-delay: 0.5s;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}


::ng-deep .mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: rgba(71, 178, 228, 1);
  border-color: rgba(71, 178, 228, 1);
}

::ng-deep .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: rgba(71, 178, 228, 1);
}